<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $t('company') }}</strong></span>
					<span v-if="company">#{{ company.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">

					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required|min:2" type="text" :label="$t('name') + ` *`" v-model="company.name" />
						</div>
						<div class="column">
							<InputWithValidation name="email" rules="required|email|min:5" type="text" :label="$t('email') + ` *`" v-model="company.email" />
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<InputWithValidation name="cep" rules="required|min:9" type="text" :label="$t('zip_code') + ` *`" v-model="company.cep" v-mask="'#####-###'" />
						</div>
						<div class="column">
							<InputWithValidation name="address" rules="required|min:10" type="text" :label="$t('address') + ` *`" v-model="company.address" />
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<InputWithValidation name="city" rules="required|min:2" type="text" :label="$t('city') + ` *`" v-model="company.city" />
						</div>
						<div class="column">
							<InputWithValidation name="state" rules="required|min:2" type="text" :label="$t('state') + ` *`" v-model="company.state" />
						</div>
					</div>

					<h3 class="is-size-6 is-semibold has-text-primary">{{ $t('personalization') }}</h3>
					<hr class="my-3">

					<div class="columns">
						<div class="column">
							<SelectWithValidation class="mb-4" :label="$t('font')" v-model="company.font">
								<option v-for="f in fonts" :value="f.code" :key="f.code" :style="`font-family: ${f.font}`">{{ f.font }}</option>
							</SelectWithValidation>
						</div>
						<div class="column">
							<InputWithValidation name="title_color" rules="min:7" type="text" :label="$t('title_color')" v-mask="'X######'" v-model="company.title_color" placeholder="#000000" />
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<InputWithValidation name="color_primary" rules="min:7" type="text" :label="$t('color_primary')" v-model="company.color_primary" placeholder="#000000" />
						</div>
						<div class="column">
							<InputWithValidation name="color_second" rules="min:7" type="text" :label="$t('color_second')" v-model="company.color_second" placeholder="#000000" />
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<InputWithValidation name="text_color_light" rules="min:7" type="text" :label="$t('text_color_light')" v-model="company.text_color_light" placeholder="#000000" />
						</div>
						<div class="column">
							<InputWithValidation name="text_color_dark" rules="min:7" type="text" :label="$t('text_color_dark')" v-model="company.text_color_dark" placeholder="#000000" />
						</div>
					</div>

					<b-field class="mb-4" :label="$t('logo_light')">
						<CropImage id="logo_light" v-model="logoLight" />
					</b-field>

					<b-field class="mb-4" :label="$t('logo_dark')">
						<CropImage id="logo_dark" v-model="logoDark" />
					</b-field>

					<b-field class="mb-4" :label="$t('image_background')">
						<CropImage id="image_background" v-model="imageBackground" />
					</b-field>

					<h3 class="is-size-6 is-semibold has-text-primary">App</h3>
					<hr class="my-3">

					<div class="columns">
						<div class="column">
							<InputWithValidation name="appbar_color_primary" rules="min:7" type="text" :label="$t('appbar_color_primary')" v-model="company.appbar_color_primary" placeholder="#000000" />
						</div>
						<div class="column">
							<InputWithValidation name="appbar_color_second" rules="min:7" type="text" :label="$t('appbar_color_second')" v-model="company.appbar_color_second" placeholder="#000000" />
						</div>
						<div class="column">
							<InputWithValidation name="appbar_textcolor" rules="min:7" type="text" :label="$t('appbar_textcolor')" v-model="company.appbar_textcolor" placeholder="#000000" />
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<InputWithValidation name="appbar_height" rules="min:1" type="text" :label="$t('appbar_height')" v-model="company.appbar_height" />
						</div>
						<div class="column">
							<b-field class="mb-4" :label="$t('appbar_logo')">
								<CropImage id="appbar_logo" v-model="appbarLogo" />
							</b-field>
						</div>
					</div>

					<InputWithValidation class="mb-4" name="custom_css" type="textarea" :label="$tc('custom_css')" v-model="company.custom_css" />

					<small class="modal-updated" v-if="company.updated_at">{{ $t('last_change') }} {{ format(company.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'
import server from '@/mixins/server'
import CropImage from '@/components/inputs/CropImage'

export default {
	mixins: [server],
	components: {
		InputWithValidation,
		SelectWithValidation,
		ValidationObserver,
		CropImage
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		},
		type: {
			type: [String, Number],
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			company: {},
			logoLight: null,
			logoDark: null,
			imageBackground: null,
			appbarLogo: null,
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			fonts: [
				{ code: 'Alegreya', font: 'Alegreya' },
				{ code: 'Barlow', font: 'Barlow' },
				{ code: 'Fraunces', font: 'Fraunces' },
				{ code: 'Lato', font: 'Lato' },
				{ code: 'Merriweather', font: 'Merriweather' },
				{ code: 'Montserrat', font: 'Montserrat' },
				{ code: 'myriad-pro', font: 'Myriad Pro' },
				{ code: 'Noto+Sans', font: 'Noto Sans' },
				{ code: 'Nunito', font: 'Nunito' },
				{ code: 'Open+Sans', font: 'Open Sans' },
				{ code: 'Oswald', font: 'Oswald' },
				{ code: 'PT+Sans', font: 'PT Sans' },
				{ code: 'Poppins', font: 'Poppins' },
				{ code: 'Raleway', font: 'Raleway' },
				{ code: 'Roboto', font: 'Roboto' },
				{ code: 'Slabo+27px', font: 'Slabo 27px' },
				{ code: 'Source+Sans+Pro', font: 'Source Sans Pro' }
			]
		}
	},
	methods: {
		async update() {
			try {
				this.loading = true
				let formData = this.getValuesForm()
				formData.append('_method', 'put')

				const response = await Api.post(`company/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if (status === 201) {
					this.$emit('close')
					successToast(this.$t('saved_success'))

					if (this.type == 'profile') {
						history.pushState({}, '', '/profile')
					} else {
						history.pushState({}, '', '/companies')
						eventHub.$emit('reload-companies')
					}
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('saved_error'))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`company/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.company = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true
				let formData = this.getValuesForm()

				const response = await Api.post('company/store', formData, this.configRequest)
				const { status } = response
				if (status === 201 || status === 200) {
					this.$emit('close')
					successToast(this.$t('saved_success'))

					if (this.type == 'profile') {
						history.pushState({}, '', '/profile')
					} else {
						history.pushState({}, '', '/companies')
						eventHub.$emit('reload-companies')
					}
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('saved_error') + '! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		getValuesForm() {
			let formData = new FormData()

			formData.append('name', this.company.name)
			formData.append('email', this.company.email || '')
			formData.append('cep', this.company.cep || '')
			formData.append('address', this.company.address || '')
			formData.append('city', this.company.city || '')
			formData.append('state', this.company.state || '')

			formData.append('font', this.company.font || '')
			formData.append('title_color', this.company.title_color || '')
			formData.append('color_primary', this.company.color_primary || '')
			formData.append('color_second', this.company.color_second || '')
			formData.append('text_color_light', this.company.text_color_light || '')
			formData.append('text_color_dark', this.company.text_color_dark || '')

			if (this.logoLight) {
				formData.append('logo_light[file]', this.logoLight.file)

				formData.append('logo_light[crop][width]', this.logoLight.crop.width)
				formData.append('logo_light[crop][height]', this.logoLight.crop.height)
				formData.append('logo_light[crop][x]', this.logoLight.crop.x)
				formData.append('logo_light[crop][y]', this.logoLight.crop.y)
			}

			if (this.logoDark) {
				formData.append('logo_dark[file]', this.logoDark.file)

				formData.append('logo_dark[crop][width]', this.logoDark.crop.width)
				formData.append('logo_dark[crop][height]', this.logoDark.crop.height)
				formData.append('logo_dark[crop][x]', this.logoDark.crop.x)
				formData.append('logo_dark[crop][y]', this.logoDark.crop.y)
			}

			if (this.imageBackground) {
				formData.append('image_background[file]', this.imageBackground.file)

				formData.append('image_background[crop][width]', this.imageBackground.crop.width)
				formData.append('image_background[crop][height]', this.imageBackground.crop.height)
				formData.append('image_background[crop][x]', this.imageBackground.crop.x)
				formData.append('image_background[crop][y]', this.imageBackground.crop.y)
			}

			formData.append('appbar_height', this.company.appbar_height || '')
			formData.append('appbar_color_primary', this.company.appbar_color_primary || '')
			formData.append('appbar_color_second', this.company.appbar_color_second || '')
			formData.append('appbar_textcolor', this.company.appbar_textcolor || '')

			if (this.appbarLogo) {
				formData.append('appbar_logo[file]', this.appbarLogo.file)

				formData.append('appbar_logo[crop][width]', this.appbarLogo.crop.width)
				formData.append('appbar_logo[crop][height]', this.appbarLogo.crop.height)
				formData.append('appbar_logo[crop][x]', this.appbarLogo.crop.x)
				formData.append('appbar_logo[crop][y]', this.appbarLogo.crop.y)
			}

			formData.append('custom_css', this.company.custom_css || '')

			return formData
		},
		async save() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
