<template>
	<Layout>
		<template>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(updatePreferences)">
					<article class="profile__column panel">
						<div class="panel__header">
							<span class="is-flex is-flex-direction-column is-justify-content-center">
								<h3 class="profile__name pt-0 is-semibold is-size-4">{{ $t('settings') }}</h3>
								<p class="is-size-6">{{ $t('system_preferences') }}</p>
							</span>
							<b-button v-if="isDesktop" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>{{ $t('save') }}</b-button>
						</div>
						<div class="panel__body">
							<h3 class="profile__section mb-5 has-text-primary is-semibold is-size-5">{{ $t('preferences') }}</h3>
							<SelectWithValidation class="profile__field" tab="5" :label="$t('language')" size="is-medium" v-model="user.language">
								<option :value="l.language" v-for="l in languages" :key="l.id">{{ l.title }}</option>
							</SelectWithValidation>

							<SelectWithValidation class="profile__field" tab="6" :label="$t('theme')" size="is-medium" v-model="user.theme">
								<option :value="t.theme" v-for="t in theme" :key="t.id">{{ t.title }}</option>
							</SelectWithValidation>

							<div class="is-flex is-justify-content-flex-end mb-3">
								<b-button v-if="!isDesktop" tabindex="7" native-type="submit" type="is-primary save" :loading="loading" rounded>{{ $t('save') }}</b-button>
							</div>
						</div>
					</article>
				</form>
			</ValidationObserver>
		</template>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Profile'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import { mapState, mapMutations } from 'vuex'
import { toast } from '@/mixins/toast'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Settings',
	mixins: [responsive],
	components: {
		Layout,
		ValidationObserver,
		SelectWithValidation
	},
	data() {
		return {
			loading: false,
			languages: [
				{
					id: 1,
					language: 'pt',
					title: 'Português'
				},
				{
					id: 2,
					language: 'en',
					title: 'English'
				},
				{
					id: 3,
					language: 'es',
					title: 'Español'
				}
			],
			theme: [
				{
					id: 1,
					theme: 'light',
					title: 'Light'
				},
				{
					id: 2,
					theme: 'dark',
					title: 'Dark'
				}
			]
		}
	},
	methods: {
		...mapMutations('user', ['setUser']),
		updatePreferences() {
			this.loading = true
			Api.post('user/preferences', this.user)
				.then(() => {
					this.setUser(this.user)
					toast('is-success', this.$t('settings_updated_success'))
				})
				.catch(() => {
					toast('is-danger', this.$t('settings_updated_error'))
				})
				.finally(() => {
					this.loading = false
				})
		}
	},
	computed: {
		...mapState('user', ['user'])
	}
}
</script>
