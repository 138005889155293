<template>
	<aside class="column profile__sidebar">
		<div class="profile__column">
			<div class="is-flex mb-0">
				<div class="profile__image">
					<b-button native-type="button" class="profile__image__edit" @click="openAvatar">
						<svg-icon icon="edit"></svg-icon>
					</b-button>
					<b-skeleton v-if="!user.avatar" width="90" height="90" animated></b-skeleton>
					<img :src="user.avatar" :alt="user.full_name" />
				</div>
				<div class="pl-3 pt-1">
					<div v-if="!user.full_name" class="profile__name">
						<b-skeleton width="160" height="25" animated></b-skeleton>
						<b-skeleton v-if="!user.permission" width="100" height="15" animated></b-skeleton>
					</div>
					<h3 class="profile__name is-semibold is-size-5 is-ellipsis">{{ user.full_name }}</h3>
					<p v-if="user.permission">{{ user.permission.name }}</p>
					<p v-if="birthday" class="profile__birthday">
						<span>{{ $t('happy_birthday') }}</span>
						<svg-icon icon="baloon"></svg-icon>
					</p>
				</div>
			</div>
			<ul v-if="isDesktop" class="profile__list mt-3">
				<li v-if="!user.full_name">
					<b-skeleton height="22" animated></b-skeleton>
				</li>
				<li v-else>
					<span class="profile__list__key">{{ $t('name') }}:</span>
					<span class="profile__list__value">{{ user.full_name }}</span>
				</li>
				<li v-if="!user.email">
					<b-skeleton height="22" animated></b-skeleton>
				</li>
				<li v-else>
					<span class="profile__list__key">E-mail:</span>
					<span class="profile__list__value">{{ user.email }}</span>
				</li>
			</ul>
			<ul class="profile__navigation mt-3">
				<li v-for="(m, i) in navigation" :key="i">
					<router-link :to="m.path">
						<svg-icon :icon="m.icon"></svg-icon>
						<span translate="no">{{ $t(m.meta.title) }}</span>
					</router-link>
				</li>

				<li v-if="this.user && this.user.role.name == 'manager'">
					<a @click.prevent="editCompany(user.company_id)">
						<svg-icon icon="company"></svg-icon>
						<span translate="no">{{ $t('company') }}</span>
					</a>
				</li>
			</ul>
		</div>
	</aside>
</template>

<script>
import Menu from '@/router/user'
import Icon from '@/components/Icon'
import Modal from '@/components/modals/Avatar'
import { responsive } from '@/mixins/responsive'
import { mapState } from 'vuex'
import moment from 'moment'
import ModalCompany from '@/components/modals/Company'
import { update } from '@/mixins/modal'

export default {
	name: 'Sidebar',
	mixins: [responsive],
	components: {
		'svg-icon': Icon
	},
	data() {
		return {
			birthday: false
		}
	},
	mounted() {
		this.checkBirthday()
	},
	methods: {
		openAvatar() {
			this.$buefy.modal.open({
				parent: this,
				component: Modal,
				scroll: 'clip',
				customClass: 'is-avatar',
				trapFocus: true
			})
		},
		checkBirthday() {
            if (this.user && this.user.birth_date) {
                const today = moment().format('DD-MM')
                const birthDate = moment(this.user.birth_date).format('DD-MM')

				if (today == birthDate) {
					this.birthday = true
				}
            }
        },
		editCompany(idCompany) {
			if (this.user && this.user.role.name == 'manager') {
				update(this, 'profile', idCompany, ModalCompany, 'Edit', null, 'profile')
			}
		}
	},
	computed: {
		...mapState('user', ['user']),
		navigation() {
			return Menu
		}
	}
}
</script>
